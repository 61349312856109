@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,300;1,400&display=swap');

.main {
    display: flex;
    width: 100%;
    height: 100vh;
    background: #F4F8FA;
    flex-direction: column;
}

.heading {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
}

.head-text {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #1B31A8;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.left-content {
    width: 35%;
    height: 70%;
    display: flex;
    flex-direction: column;
    margin-left: 6%;
}

.top {
    display: flex;
    /* flex-direction: column; */
    height: 68%;
}

.top-left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

label {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #000000;
    width: 143px;
    height: 25px;
    position: relative;
    top: 6%;

}

.top-right {
    display: flex;
    width: 50%;
}

input#origin {
    width: 272px;
    height: 56px;
    border-radius: 4px;
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #4D6475;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    padding-left: 11%;
}

input#destination {
    width: 272px;
    height: 56px;
    border-radius: 4px;
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #4D6475;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    padding-left: 11%;
}
img.inputimage {
    width: 27px;
    height: 27px;
    position: relative;
    top: 18%;
    left: 2%;
}

button {
    margin: auto;
    width: 141px;
    height: 62px;
    position: relative;
    top: 8%;
    left: 25%;
    border-radius: 32px;
    background: #1B31A8;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: none;
}
button:hover{
    background-color: #ffffff;
    color: #1B31A8;
    border:1px solid #1B31A8;
}

.bottom {
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 35%;
    margin-top: 9%;
    border: 1px solid lightgray;
}

.bottom-top {
    display: flex;
    height: 50%;

}

.text-bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #ffffff;
    padding: 10px;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
}

p.text-distance {

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    margin-left: 2%;
}

p.text-km {

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 120%;
    color: #0079FF;
    flex: none;
    order: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 3%;
}

.bottom-bottom {
    height: 50%;
    display: flex;
    align-items: center;
    padding-left: 3%;
}

p.text-last {
    width: 426px;
    height: 14px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #1E2A32;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: auto;
}
.right-content {
    width: 39%;
    display: flex;
    padding: 3%;
    height: 70%;
}
.container {
    margin: auto;
}
.leaflet-container{
    height: 400px;
    width: 400px;
}